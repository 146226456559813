import React from "react";
import { Outlet } from "react-router-dom";
import { Layout, Row, notification, message } from "antd";
import { HeaderContent, FooterContent } from "components";

const { Header, Footer, Content } = Layout;

export class Container extends React.Component {
  render() {
    // Ant Design - Global setting for all notifications
    notification.config({ top: 10 });
    message.config({ top: 10, duration: 6 });

    const headerStyle = {
      style: {
        marginBottom: 8,
        height: "56px",
        lineHeight: "56px",
        background: "#fff",
      },
    };

    return (
      <Layout>
        <Header {...headerStyle}>
          <HeaderContent />
        </Header>
        <Content style={{ minHeight: 400 }}>
          <Row justify="center">
            <Outlet />
          </Row>
        </Content>
        <Footer>
          <FooterContent />
        </Footer>
      </Layout>
    );
  }
}

export default Container;
