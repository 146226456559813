import { convertGraphQLError } from "utils/apolloUtils";

function NiceErrorNode(props: any) {
  let childs = [];
  const costumErrors = convertGraphQLError(props);
  const { messages = [] } = costumErrors;

  if (messages.length !== 0) {
    messages.forEach((message: any) => {
      const nodes = message
        .split("\n")
        .map((text: any, index: any) => <p key={`error-${index}`}>{text}</p>);
      childs.push(nodes);
    });
  }

  if (childs.length === 0) {
    childs.push(
      <div key="unknown-err">
        <p key="unknown-err-1">An unknown problem has occurred.</p>
        <p key="unknown-err-2">
          Please contact customer support for further assistance.
        </p>
      </div>,
    );
  }

  return <div id="graphqlErrors">{childs}</div>;
}

/*
NiceErrorNode.propTypes = {
  graphQLErrors: PropTypes.array,
  networkError: PropTypes.object,
};
*/

export default NiceErrorNode;
