import {
  Button,
  Form,
  Input,
  Row,
  Card,
  Space,
  Radio,
  Alert,
  Typography,
  Spin,
  Modal,
  Skeleton,
  Result,
} from "antd";
import { Navigate } from "react-router-dom";
import { ExclamationCircleFilled } from "@ant-design/icons";
import React, { useState } from "react";
import { gql } from "graphql.macro";
import { useMutation } from "@apollo/client";
import { useAuth } from "AuthContext";
import { useLocalStorage } from "utils/localStorageUtils";

const { Text } = Typography;
const { confirm } = Modal;

const defineApiChannelName = "FileApiAboReminder";
const defineKeyLastRequestId = "aborm_lastreqid";
const isDevelopment = process.env.NODE_ENV === "development";

const muationPayload = gql`
  mutation (
    $emailAddr: String!
    $subscriptionRevoked: Boolean!
    $channelToken: String!
  ) {
    subscriptionReminder(
      emailAddr: $emailAddr
      subscriptionRevoked: $subscriptionRevoked
      channelToken: $channelToken
    ) {
      emailAddr
      subscriptionRevoked
      reminderCreatedTime
      comment
    }
  }
`;

function PageAboReminder() {
  const [submitting, setSubmitting] = useState(false);
  const [lastRequestId, setLastRequestId] = useLocalStorage(
    defineKeyLastRequestId,
    "*",
  );

  const auth = useAuth();
  var initValueEmail = "";

  const [funcMutation, { loading, error, data }] = useMutation(muationPayload, {
    onCompleted: () => {
      setSubmitting(false);
      setLastRequestId(auth.authResult?.mm_request_uid);
    },
    onError: () => setSubmitting(false),
  });

  const RunMutation = (values: any) => {
    const { email, choice } = values;
    funcMutation({
      variables: {
        emailAddr: email,
        subscriptionRevoked: choice !== "OPT_IN",
        channelToken: auth.authResult?.mm_channel_token,
      },
    });
  };

  const RunShowConfirm = (values: any) => {
    confirm({
      title: "Abonnement beenden",
      icon: <ExclamationCircleFilled />,
      content: "Sind Sie sicher, dass Sie sich abmelden möchten?",
      onOk() {
        RunMutation(values);
      },
      onCancel() {
        setSubmitting(false);
      },
    });
  };

  const onFormFinish = (values: any) => {
    if (submitting) return;
    setSubmitting(true);

    const { choice } = values;

    if (choice !== "OPT_IN") {
      RunShowConfirm(values);
    } else {
      RunMutation(values);
    }
  };

  const RenderErrorMessage = () => {
    if (error) {
      return (
        <Alert
          description={error.message}
          type="error"
          showIcon
          style={{ marginBottom: 12 }}
        />
      );
    }
    return null;
  };

  if (data) {
    const { subscriptionReminder } = data;
    let revoked = subscriptionReminder.subscriptionRevoked;
    return <Navigate to="/aboreminderdone" state={{ revoked }} replace />;
  }

  if (!auth.authResult?.email) {
    return (
      <Row justify="center">
        <Card style={{ width: 600 }}>
          <Skeleton active />
        </Card>
      </Row>
    );
  }

  // Check the channel name: Are we right here at this path for AboReminder?
  // If so, we should find the correct channel name in auth object:
  if (auth.authResult?.mm_channel_name !== defineApiChannelName) {
    return (
      <Row justify="center">
        <Card style={{ width: 600 }}>
          <Alert
            description={`Invalid authentification: Channel name '${defineApiChannelName}' not found.`}
            type="error"
            showIcon
            style={{ marginBottom: 12 }}
          />
        </Card>
      </Row>
    );
  }

  // Check if this request already has been submitted in the past:
  if (!loading && auth.authResult?.mm_request_uid === lastRequestId) {
    return (
      <Row justify="center">
        <Card style={{ width: 600 }}>
          <Result
            status="warning"
            title="Diese Anfrage wurde bereits versendet."
            extra={
              isDevelopment && (
                <Text type="secondary">
                  [DEBUG-INFO]: Please remove local storage key:{" "}
                  {defineKeyLastRequestId}
                </Text>
              )
            }
          />
          <Text type="secondary">
            Das erneute Versenden von Anfragen wird nicht unterstützt. Möchten
            Sie dennoch den Vorgang unbedingt erneut durchführen, dann löschen
            Sie bitte zuvor den Browser-Cache.
          </Text>
        </Card>
      </Row>
    );
  }

  initValueEmail = auth.authResult.email;

  return (
    <Row justify="center">
      <Spin spinning={loading} delay={500}>
        <Card title="Ihr Abonnement-Status" style={{ maxWidth: 600 }}>
          <RenderErrorMessage />
          <Form
            name="aboreminder"
            layout="vertical"
            onFinish={onFormFinish}
            autoComplete="off"
          >
            <Space
              direction="vertical"
              size="small"
              style={{ display: "flex" }}
            >
              <Text>
                Zur Aktualisierung der Datenschutzeinstellungen möchten wir Sie
                bitten, Ihren Abonnement-Status zu übermitteln:
              </Text>
              <Text>
                Ihre E-Mail-Adresse:
                <br />
                <Text strong>{initValueEmail}</Text>
              </Text>
              <Form.Item
                name="email"
                label="E-Mail"
                hidden
                initialValue={initValueEmail}
                rules={[
                  {
                    type: "email",
                    message: "Keine gültige E-Mail-Adresse.",
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="choice"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Bitte wählen Sie eine Option."),
                          ),
                  },
                ]}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value={"OPT_IN"}>
                      Ja, ich möchte weiterhin Informationen erhalten.
                    </Radio>
                    <Radio value={"OPT_OUT"}>
                      Nein. Ich möchte das Abo beenden.
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={submitting}>
                  Absenden
                </Button>
              </Form.Item>
            </Space>
          </Form>
          <Text type="secondary">
            Sie sind als Abonnent von E.ON Marktinformationen registriert. Das
            können Marktberichte, ausführliche Marktanalysen, Marktpreis-Signale
            oder andere Informationsarten für Commodity Strom und/oder Gas sein.
          </Text>
        </Card>
      </Spin>
    </Row>
  );
}

export default PageAboReminder;
