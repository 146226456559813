import { Row, Col, Typography, Divider } from "antd";
import { CopyrightOutlined, InfoCircleOutlined } from "@ant-design/icons";
import version from "../VersionContent/VersionContent";
const { Text } = Typography;

function FooterContent() {
  return (
    <div>
      <Divider />
      <Row>
        <Col span={12}>
          <Text type="secondary" style={{ fontSize: "small" }}>
            <CopyrightOutlined />
            {" 2024, E.ON Portfolio Solutions GmbH"}
          </Text>
        </Col>
        <Col span={12}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Text type="secondary" style={{ fontSize: "small" }}>
              <InfoCircleOutlined /> v{version}
            </Text>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default FooterContent;
