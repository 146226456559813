import React from "react";
import { AuthResult } from "utils/authUtils";

interface AuthContextType {
  authResult?: AuthResult;
  signin: (authResult: AuthResult, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}
export let AuthContext = React.createContext<AuthContextType>(null!);

export function useAuth() {
  return React.useContext(AuthContext);
}
