import { Alert, Button, Card, Row } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { FormTableControl } from "components";
import moment from "moment";
import { gql } from "graphql.macro";
import { useQuery, NetworkStatus } from "@apollo/client";

const queryPayload = gql`
  query HelloServer {
    helloServer {
      websiteName
      websiteInstanceId
      azureFunctionEnvironment
      hostTime
      myVersion
    }
  }
`;

const graphQlApi = process.env.REACT_APP_GRAPHQL_API;

function HelloServer() {
  const { loading, error, data, refetch, networkStatus } = useQuery(
    queryPayload,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  function isLoading() {
    return loading || networkStatus === NetworkStatus.refetch;
  }

  const RenderHelloServerData = () => {
    var dataSource = [];

    if (data && data.helloServer) {
      const { helloServer } = data;
      let hostTime = moment(helloServer.hostTime).format(
        "YYYY-MM-DD, HH:mm:ss",
      );
      dataSource.push({ label: "GraphQL Host:", value: graphQlApi });
      dataSource.push({ label: "Host Time:", value: hostTime });
      dataSource.push({ label: "Host Version:", value: helloServer.myVersion });
    }

    return (
      <FormTableControl
        pagination={false}
        size="small"
        dataSource={dataSource}
      />
    );
  };

  const RenderErrorMessage = () => {
    if (error) {
      return (
        <Alert
          description={error.message}
          type="error"
          showIcon
          style={{ marginBottom: 12 }}
        />
      );
    }
    return null;
  };

  return (
    <Row justify="center">
      <Card
        title="Remote Host Information"
        loading={loading}
        style={{ maxWidth: 1024 }}
        extra={
          <span>
            <Button
              style={{ marginLeft: 8 }}
              loading={loading}
              icon={<ReloadOutlined />}
              onClick={() => refetch()}
              disabled={isLoading()}
            />
          </span>
        }
      >
        <RenderErrorMessage />
        <RenderHelloServerData />
      </Card>
    </Row>
  );
}

export default HelloServer;
