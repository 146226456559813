import React from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import "./FormTableControl.css";

function FormTableControl(props: any) {
  const {
    columLabelWidth = "40%",
    columValueWidth = "60%",
    tableSize = "middle",
  } = props;

  const columns = [
    {
      title: "Label",
      dataIndex: "label",
      width: columLabelWidth,
      className: "column-label",
    },
    {
      title: "Value",
      dataIndex: "value",
      width: columValueWidth,
    },
  ];

  return (
    <Table
      rowKey={(record) => record.label}
      columns={columns}
      pagination={false}
      showHeader={false}
      bordered={false}
      size={tableSize}
      {...props}
    />
  );
}

FormTableControl.prototype = {
  dataSource: PropTypes.array.isRequired,
  columLabelWidth: PropTypes.any,
  columValueWidth: PropTypes.any,
  tableSize: PropTypes.string,
};

export default FormTableControl;
