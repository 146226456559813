import React from "react";
import {
  BrowserRouter as Router,
  useLocation,
  Navigate,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { ConfigProvider, BackTop, Row, Card, Result } from "antd";
import deDE from "antd/lib/locale/de_DE";
import PageLayout from "./views/PageLayout";
import Home from "./views/PageHome";
import HelloServer from "./views/PageHelloServer";
import PageNotFound from "./views/PageNotFound";
import PageAboReminder from "./views/PageAboReminder";
import PageAboReminderDone from "views/PageAboReminderDone";
import { AuthContext, useAuth } from "./AuthContext";
import { AuthResult } from "utils/authUtils";

const isDevelopment = process.env.NODE_ENV === "development";

// --------------------------------------------------------
// Base router documentation:
// https://reactrouter.com/en/main/routers/picking-a-router
//
// Router Auth Sample:
// https://github.com/remix-run/react-router/blob/dev/examples/auth/src/App.tsx
// ------------

let authUtils = require("utils/authUtils");

function AuthProvider({ children }: { children: React.ReactNode }) {
  let [authResult, setAuthResult] = React.useState<any>(null);

  let signin = (newAuthResult: AuthResult, callback: VoidFunction) => {
    return authUtils.jwtAuthProvider.signin(() => {
      setAuthResult(newAuthResult);
      callback();
    });
  };

  let signout = (callback: VoidFunction) => {
    return authUtils.jwtAuthProvider.signout(() => {
      setAuthResult(null);
      callback();
    });
  };

  let value = { authResult, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth?.authResult && /jwt/.test(location.hash)) {
    let token = authUtils.getSearchFragment("jwt", location.hash);
    let authResult = authUtils.parseToken(token);

    if (authResult && authResult.email) {
      auth.signin(authResult, () => {
        if (isDevelopment) {
          console.log("JwtAuthentifiation:", authResult);
        }
      });
      return children;
    }

    if (authResult && authResult.error) {
      console.warn("error:", authResult.error);
    }
  }

  if (!auth.authResult?.email) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function LoginPage() {
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  let from = location.state?.from?.pathname || "/";

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let username = formData.get("username") as string;
    let newAuthResult: AuthResult = {
      email: username,
    };
    auth.signin(newAuthResult, () => {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      navigate(from, { replace: true });
    });
  }

  if (!isDevelopment) {
    return (
      <Row justify="center">
        <Card style={{ maxWidth: 600 }}>
          <Result
            status="500"
            title="Etwas scheint nicht zu funktionieren."
            subTitle="Bitte schließen Sie das Fenster und versuchen Sie es noch einmal."
          />
        </Card>
      </Row>
    );
  }

  return (
    <div>
      <p>
        [DEBUG] You must log in to view the page:
        <br />
        {from}
      </p>

      <form onSubmit={handleSubmit}>
        <label>
          Username: <input name="username" type="text" />
        </label>{" "}
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default function App() {
  return (
    <div style={{ height: "100%" }}>
      <Router>
        <ConfigProvider locale={deDE}>
          <BackTop />
          <AuthProvider>
            <Routes>
              <Route path="/" element={<PageLayout />}>
                <Route index element={<Home />} />
                <Route path="/login" element={<LoginPage />} />
                <Route
                  path="/aboreminder"
                  element={
                    <RequireAuth>
                      <PageAboReminder />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/aboreminderdone"
                  element={<PageAboReminderDone />}
                />
                <Route path="/sysinfo" element={<HelloServer />} />
                <Route path="*" element={<PageNotFound />} />
              </Route>
            </Routes>
          </AuthProvider>
        </ConfigProvider>
      </Router>
    </div>
  );
}
