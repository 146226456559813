import { Row, Card, Typography, Result } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import React from "react";

const { Text } = Typography;

function PageAboReminderDone() {
  let location = useLocation();
  let revoked = location.state?.revoked;

  return (
    <Row justify="center">
      <Card style={{ width: 600 }}>
        <Result
          status="success"
          title="Die Nachricht wurde übermittelt."
          subTitle="Wir bedanken uns für Ihr Feedback."
        />
        <div>
          {revoked && (
            <Text type="secondary">
              <InfoCircleOutlined /> Die Kündigung des Abonnement erfolgt
              automatisiert und kann einige Tage in Anspruch nehmen. Eine
              Zustellung weiterer Nachrichten bis zur Fertigstellung kann daher
              nicht ausgeschlossen werden.
            </Text>
          )}
        </div>
      </Card>
    </Row>
  );
}

export default PageAboReminderDone;
