import React from "react";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

import ReactDOM from "react-dom/client";
import "./index.less";
import App from "./App";

const graphQlApi = process.env.REACT_APP_GRAPHQL_API;

const client = new ApolloClient({
  uri: graphQlApi,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
);
