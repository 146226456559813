import jwt_decode from "jwt-decode";

export const getSearchFragment = (fragmentName: string, hash: string) => {
  var value = "&" + hash.substring(1);
  var parts = value.split("&" + fragmentName + "=");
  if (parts.length === 2) {
    return parts?.pop()?.split("&").shift();
  }
};

export interface MmJwtPayload {
  mm_channel_name?: string;
  mm_channel_token?: string;
  mm_request_uid?: string;
  email?: string;
  aud?: string[] | string;
  exp: number;
  iat: number;
}

export interface AuthResult {
  mm_channel_name?: string;
  mm_channel_token?: string;
  mm_request_uid?: string;
  email?: string;
  expiresAt?: number;
  error?: any;
}

export function parseToken(token: string): AuthResult {
  try {
    const decoded = jwt_decode<MmJwtPayload>(token);

    if (decoded.email == null) throw new Error("Missing Parameter: email");

    if (decoded.mm_channel_name == null)
      throw new Error("Missing Parameter: mm_channel_name");

    if (decoded.mm_channel_token == null)
      throw new Error("Missing Parameter: mm_channel_token");

    if (decoded.mm_request_uid == null)
      throw new Error("Missing Parameter: mm_request_uid");

    const result: AuthResult = {
      mm_channel_name: decoded.mm_channel_name,
      mm_channel_token: decoded.mm_channel_token,
      mm_request_uid: decoded.mm_request_uid,
      email: decoded.email,
      expiresAt: decoded.exp,
    };

    return result;
  } catch (error: any) {
    const result: AuthResult = {
      error: error,
    };
    return result;
  }
}

export const jwtAuthProvider = {
  isAuthenticated: false,
  signin(callback: VoidFunction) {
    jwtAuthProvider.isAuthenticated = true;
    setTimeout(callback, 100);
  },
  signout(callback: VoidFunction) {
    jwtAuthProvider.isAuthenticated = false;
    setTimeout(callback, 100);
  },
};
