import { Row, Col, Menu } from "antd";
import {
  FileDoneOutlined,
  InfoCircleOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate, Link } from "react-router-dom";
import logo from "assets/logo_eon.svg";
import React from "react";
import { ItemType } from "antd/lib/menu/hooks/useItems";

const isDevelopment = process.env.NODE_ENV === "development";

var routeMenuItems: any[];

if (!isDevelopment) {
  routeMenuItems = [
    {
      key: "app:sysinfo",
      label: "",
      icon: <InfoCircleOutlined />,
      path: "/sysinfo",
    },
  ];
} else {
  routeMenuItems = [
    {
      key: "app:aboreminder",
      label: "",
      icon: <MailOutlined />,
      path: "/aboreminder/#jwt=eyJ0eXAiOiJKV1QiLCJhbGciOiJub25lIn0.eyJtbV9jaGFubmVsX25hbWUiOiJGaWxlQXBpQWJvUmVtaW5kZXIiLCJtbV9jaGFubmVsX3Rva2VuIjoiY2U2OWI3Y2EtODk0ZS00ZmRjLWI4MzUtNzhhM2MwYTk3MmJiLWRldiIsIm1tX3JlcXVlc3RfdWlkIjoiNGJkMzJjZDQtNzFhYy00ZDVlLTlhMjctZjkyMDUwMzIwZDEyIiwiZW1haWwiOiJtYXJ2aW4ubXVzdGVybWFubkBnbWFpbC5jb20iLCJpYXQiOjE2NzM0NDc4NTYsImV4cCI6MTY3MzQ0Nzg1Nn0.",
    },
    {
      key: "app:aboreminderdone",
      label: "",
      icon: <FileDoneOutlined />,
      path: "/aboreminderdone",
    },
    {
      key: "app:sysinfo",
      label: "",
      icon: <InfoCircleOutlined />,
      path: "/sysinfo",
    },
  ];
}

const HeaderContent = () => {
  let navigate = useNavigate();
  const selectedKey = useLocation().pathname;

  const highlight = () => {
    if (selectedKey === "/aboreminder") {
      return ["app:aboreminder"];
    } else if (selectedKey === "/sysinfo") {
      return ["app:sysinfo"];
    }
  };

  const onClickMenuItem = (props: any) => {
    const { key } = props;
    const foundItem = routeMenuItems.filter((item) => item.key === key)[0];
    navigate(foundItem.path);
  };

  var items: ItemType[] = routeMenuItems.map((item) => {
    return { key: item.key, label: item.label, icon: item.icon };
  });

  return (
    <Row justify="center">
      <Col span={12}>
        <Link to="/">
          <img
            style={{ verticalAlign: "middle", height: 20 }}
            src={logo}
            alt="EON"
          />
        </Link>
      </Col>
      <Col span={12}>
        <Menu
          items={items}
          selectedKeys={highlight()}
          mode="horizontal"
          onClick={(props) => onClickMenuItem(props)}
          style={{ display: "flex", justifyContent: "flex-end" }}
        />
      </Col>
    </Row>
  );
};

export default HeaderContent;
