import { Result, Card } from "antd";
import { ExceptionOutlined } from "@ant-design/icons";

import QueueAnim from "rc-queue-anim";

const REACT_APP_MAIN_TITLE = process.env.REACT_APP_MAIN_TITLE;

function Home() {
  return (
    <Card bordered={false} style={{ maxWidth: 640 }}>
      <QueueAnim delay={400}>
        <div key="1">
          <Result
            icon={<ExceptionOutlined />}
            title={`${REACT_APP_MAIN_TITLE}: Sorry, resource is not available!`}
          />
        </div>
      </QueueAnim>
    </Card>
  );
}

export default Home;
