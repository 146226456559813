import { Component } from "react";
import { Link } from "react-router-dom";
import { Card, Result, Row } from "antd";

export default class PageNotFound extends Component {
  render() {
    return (
      <Row justify="center">
        <Card title="Seite nicht gefunden" style={{ width: 600 }}>
          <Result
            status="404"
            title="404"
            subTitle="Es kann vielfältige Gründe für das Verschwinden von
            Internetseiten geben. Nicht immer kann dieses Problem sofort
            gelöst werden."
            extra={<Link to="/">Startseite versuchen?</Link>}
          />
        </Card>
      </Row>
    );
  }
}
